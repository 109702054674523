<script lang="ts" setup>
import type { PlayersActivityArray, PlayersActivityGame } from "@/types";

defineProps<{ item: PlayersActivityArray[number] }>();
defineEmits<{ (event: "openGame", item: PlayersActivityGame): void }>();

const { t } = useT();
const isGuest = useIsGuest();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();
</script>

<template>
	<div class="feed-card" @click="$emit('openGame', item?.data)">
		<div class="feed-card__image">
			<NuxtImg
				v-if="item?.type === 'jackpot' || item?.type === 'daily-wheel-accrual' || item?.type === 'bigwin'"
				class="feed-card__image-overlay"
				:src="`/nuxt-img/activity-feed/${item?.type}.png`"
				:alt="item?.type"
				:data-tid="item?.data?.slug"
				width="76"
				height="85"
				format="webp"
			/>
			<NuxtImg
				v-else-if="item?.data?.logo"
				:src="`${baseImageUrl}${item?.data?.logo}`"
				:alt="item?.data?.slug"
				:data-tid="item?.data?.slug"
				width="76"
				height="85"
				format="webp"
			/>
			<ABadge v-if="item?.data?.gameExclusiveData?.isExclusive" autosize variant="info" background="var(--coimbatore)">
				<AText class="text-chaozhou" variant="trieste" :modifiers="['bold', 'uppercase']">
					{{ t("SC exclusive") }}
				</AText>
			</ABadge>
		</div>

		<div class="feed-card__data">
			<div class="feed-card__values">
				<template v-if="item?.type === 'win' && item?.data?.winAmount">
					<MPrizeFund
						:variant="item?.gameMode === 'SweepStakes' && !isGuest ? 'entries' : 'coins'"
						:icon="item?.gameMode === 'SweepStakes' && !isGuest ? '20/entries' : '20/coins'"
						:icon-size="24"
					>
						<AText
							variant="taipei"
							:data-tid="`win-amount-${item?.gameMode}`"
							:modifiers="['bold']"
							:class="item?.gameMode === 'SweepStakes' && !isGuest ? 'text-changchun' : 'text-coimbatore'"
						>
							{{ numberFormat(item?.data?.winAmount) }}
						</AText>
					</MPrizeFund>
				</template>
				<template v-if="item?.type === 'bigwin'">
					<MPrizeFund
						:variant="item?.gameMode === 'SweepStakes' && !isGuest ? 'entries' : 'coins'"
						icon="24/big-win"
						:icon-size="24"
					>
						<AText
							variant="taipei"
							:data-tid="`bigwin-amount-${item?.gameMode}`"
							:modifiers="['bold']"
							:class="item?.gameMode === 'SweepStakes' && !isGuest ? 'text-changchun' : 'text-coimbatore'"
						>
							{{ numberFormat(item.data.big_win_coefficient) }}
						</AText>
					</MPrizeFund>
				</template>

				<template v-if="item?.type === 'jackpot'">
					<MPrizeFund
						:variant="item?.gameMode === 'SweepStakes' && !isGuest ? 'entries' : 'coins'"
						:icon="item?.gameMode === 'SweepStakes' && !isGuest ? '20/entries' : '20/coins'"
						:icon-size="24"
					>
						<AText
							variant="taipei"
							:data-tid="`jackpot-amount-${item?.gameMode}`"
							:modifiers="['bold']"
							:class="item?.gameMode === 'SweepStakes' && !isGuest ? 'text-changchun' : 'text-coimbatore'"
						>
							{{ numberFormat(item.data.amount ?? 0) }}
						</AText>
					</MPrizeFund>
				</template>
				<template v-if="item?.type === 'daily-wheel-accrual'">
					<MPrizeFund
						:variant="item?.data?.entries && !isGuest ? 'entries' : 'coins'"
						:icon="item?.data?.entries && !isGuest ? '20/entries' : '20/coins'"
						:icon-size="24"
					>
						<AText
							variant="taipei"
							:data-tid="`daily-wheel-amount-${item?.gameMode}`"
							:modifiers="['bold']"
							:class="item?.data?.entries && !isGuest ? 'text-changchun' : 'text-coimbatore'"
						>
							{{ numberFormat(item.data.entries || item.data.coins) }}
						</AText>
					</MPrizeFund>
				</template>
			</div>
			<AText class="text-caracas feed-card__text" data-tid="winner-name">
				{{ item?.name || item?.nickname }} {{ item?.surname }}
			</AText>
		</div>
	</div>
</template>

<style lang="scss">
.feed-card {
	background: var(--canosa);
	border-radius: 12px;
	display: flex;
	align-items: center;
	padding: gutter(0.75) gutter(2) gutter(0.75) gutter(1);
	position: relative;
	gap: gutter(1);
	cursor: pointer;

	@include media-breakpoint-down(md) {
		padding-right: gutter(1.5);
	}

	&__image {
		width: 76px;
		height: 85px;
		border-radius: 8px;
		overflow: hidden;
		position: relative;
		flex-shrink: 0;

		@include media-breakpoint-down(lg) {
			width: 57px;
			height: 64px;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		.badge {
			position: absolute;
			top: 2px;
			left: 2px;
			--a-badge-info-line-height: 0;
			--a-badge-info-height: 10px;
		}
	}

	&__values {
		display: flex;
		flex-direction: column;
	}

	&__data {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}

	&__text {
		margin-top: gutter(0.75);
		white-space: nowrap;
	}
}
</style>
